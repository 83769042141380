import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/seo"

import * as S from "../components/StyledError/styled"

const Blog = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Blog em produção" />
      <S.ErrorWrapper>
        <S.ErrorTitle>Blog</S.ErrorTitle>
        <S.ErrorTitleSecond>Página em construção</S.ErrorTitleSecond>
        <S.ErrorDescription>Você acabou de pegar uma rota que ainda não existe...</S.ErrorDescription>
        <S.ErrorLink to="/">Voltar para o início!</S.ErrorLink>
      </S.ErrorWrapper>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
