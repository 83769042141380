import styled from 'styled-components';
import media from "styled-media-query";
import { Link } from 'gatsby';

export const ErrorWrapper = styled.section`
    width: 100%;
    max-width: 1150px;
    padding: 100px 20px 100px;
    margin: 0 auto;

    ${media.lessThan("medium")`
        width: auto;
        height: auto;
    `}
`

export const ErrorTitle = styled.h1`
    margin: 0 0 10px;
    font-size: 5rem;
    background: -webkit-linear-gradient(#1cffb3, #5996f0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    ${media.lessThan("medium")`
        font-size: 3rem;
    `}
`

export const ErrorTitleSecond = styled.h1`
    margin: 0 0 10px;
    font-size: 3rem;
    background: -webkit-linear-gradient(#1cffb3, #5996f0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    ${media.lessThan("medium")`
        font-size: 2.2rem;
    `}
`

export const ErrorDescription = styled.p`
    margin-bottom: 40px; 
    font-size: 1.4rem;

    ${media.lessThan("medium")`
        font-size: 1.2rem;
    `}
`

export const ErrorLink = styled(Link)`
    text-decoration: none;
    background: -webkit-linear-gradient(#1cffb3, #5996f0);
    color: var(--color-background);
    border-radius: 0.4rem;
    padding: 0.7rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s ease-in;

    &:hover {
        background: transparent;
        color: var(--color-text);
    }
`